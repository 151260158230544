import Logo from "../assets/logo_dark.svg";
import LoginImg from "../assets/login.svg";
import { Button, Col, Input, Row } from "antd";
import {
  MailOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
  LockOutlined,
} from "@ant-design/icons";
import React from "react";

export default function ChangePassword() {
  return (
    <>
      <div className="main_wrapper">
        <div className="login_container">
          <Row gutter={20} align={"middle"}>
            <Col span="12">
              <div className="login_img_card">
                <img src={LoginImg} alt="login_img" className="login_img" />
              </div>
            </Col>
            <Col span="12">
              <div className="credentials_card">
                <div className="card">
                  <img src={Logo} alt="logo" width={200} />
                  <h1 className="title">Welcome back!</h1>
                  <p className="para">Please enter your email</p>

                  <Input
                    size="large"
                    placeholder="Email Address"
                    prefix={<MailOutlined />}
                    sizes={"large"}
                    className="mb_10"
                  />

                  <Button type="primary" className="login_btn">
                    Next
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
