// Common
export const GET_COUNTRY_LIST = "/getCountry"
export const GET_REGION_LIST = "/getRegion"

// Login
export const USER_LOGIN = '/login'

// Subscriptions
export const ADD_SUBSCRIPTION = '/addSubscription'
export const GET_ALL_SUBSCRIPTION = "/getSubscription"
export const DELETE_SUBSCRIPTION = "/deleteSubscription"
export const UPDATE_SUBSCRIPTION = "/updateSubscription"

// Users
export const GET_USERS = "/getUser"
export const ADD_USERS = "/addUser"
export const UPDATE_USERS = "/updateUser"
export const DELETE_USERS = "/deleteUser"

// Disorders
export const GET_ALL_DISORDER = "/getDisorder"
export const ADD_DISORDER = "/addDisorder"
export const UPDATE_DISORDER = "/updateDisorder"
export const DELETE_DISORDER = "/deleteDisorder"

// Materials
export const GET_ALL_MATERIAL = "/getMaterial"
export const ADD_MATERIAL = "/addMaterial"
export const DELETE_MATERIAL = "/deleteMaterial"
export const UPDATE_MATERIAL = "/updateMaterial"

