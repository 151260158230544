import { makeAutoObservable, runInAction } from "mobx"
import { postRequest } from "../utils/api"
import { GET_COUNTRY_LIST, GET_REGION_LIST } from "../constants/apiConstants"
import { Country, State } from "country-state-city"

class GlobalStore {

    countryList = []
    regionList = []
    selectedCountry = {}

    constructor() {
        makeAutoObservable(this)
    }

    getAllCountryList = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response, error } = await postRequest(GET_COUNTRY_LIST)

                if (!error && response.success) {
                    this.setCountryList(response.data)
                    return resolve({ success: true, data: response.data })
                }
                return reject({ success: false, data: {} })
            } catch (error) {
                return reject({ success: false, data: {} })
            }
        })
    }

    getAllRegionList = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response, error } = await postRequest(GET_REGION_LIST)

                if (!error && response.success) {
                    this.setRegionList(response.data)
                    return resolve({ success: true, data: response.data })
                }
                return reject({ success: false, data: {} })
            } catch (error) {
                return reject({ success: false, data: {} })
            }
        })
    }

    getCountryList = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                const countries = Country.getAllCountries()

                console.log('countries', countries)

                this.setCountryList(countries)

                resolve({ success: true, data: countries })
                
            } catch (error) {
                return reject({ success: false, data: [], error })
            }
        })  
    }

    getRegionByCountry = async (country) => {

        console.log(country, "MMMMMMMMMMMM")


        return new Promise(async (resolve, reject) => {
            try {

                const cont = Country.getCountryByCode(country)

                this.setSelectedCountry(cont)


                const regions = State.getStatesOfCountry(country)

                console.log('regions', regions)


                this.setRegionList(regions)

                resolve({ success: true, data: regions })
            } catch (error) {
                return reject({ success: false, data: [], error })
            }
        })
    }

    setCountryList = (data = []) => {
        runInAction(() => {
            this.countryList = data
        })
    }

    setRegionList = (data = []) => {
        runInAction(() => {
            this.regionList = data
        })
    }

    setSelectedCountry = (data = {}) => {
        runInAction(() => {
            this.selectedCountry = data
        })
    }
}

export default GlobalStore