import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  Input,
  Radio,
  Row,
  Upload,
  message,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Observer } from "mobx-react";
import { useMainStore } from "../../store/mainStore";
import { autorun, toJS } from "mobx";

const EditNewDocumentDrawer = (props) => {

  const mainStore = useMainStore();
  const { disorderStore } = mainStore;

  const [disabled, setDisabled] = useState(false);

  const [editDocumentForm] = Form.useForm();

  const handleDocumentUpdate = async (values) => {
    const {
      setEditDocumentMaterialModalStatus,
      editDocumentMaterialModalStatus,
      singleDocumentMaterial,
      updateAMaterial,
      singleDisorderData, getMaterial
    } = disorderStore;

    const newBody = {
      ...values,
      isNewMaterial: values.isNewMaterial ? true : false,
      materialId: singleDocumentMaterial._id,
    }

    updateAMaterial(newBody).then(() => {
      message.success('Material updated successfully')
      const [{ _id }] = singleDisorderData;

    if (!_id) return;

    const newBody = {
      disorderId: _id,
      materialType: "DOCUMENT",
    };

    getMaterial(newBody)
      setEditDocumentMaterialModalStatus(false)
    }).catch(() => {
      message.error('Failed to update material')
    })

  }

  

  return (
    <Observer>
      {() => {
        const {
          setEditDocumentMaterialModalStatus,
          editDocumentMaterialModalStatus,
          singleDocumentMaterial
        } = disorderStore;
        // console.log('singleDocumentMaterial', toJS(singleDocumentMaterial))
        const { uploadedFile, isNewMaterial, ...rest} = singleDocumentMaterial
        return (
          <Drawer
            title="Edit Document Material"
            width={500}
            onClose={() => setEditDocumentMaterialModalStatus(false)}
            open={editDocumentMaterialModalStatus}
            maskClosable={false}
          >
            <Form form={editDocumentForm} onFinish={handleDocumentUpdate} initialValues={{...rest, isNewMaterial: isNewMaterial ? 1 : 0}} >
              <Row>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="DOCUMENT NAME"
                    name="name"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                    rules={[{ required: true, message: "Please enter a document name" }]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="DESCRIPTION"
                    name="description"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                    rules={[{ required: true, message: "Please enter a description" }]}
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="MATERIAL ACCESS"
                    name="access"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                    rules={[{ required: true, message: "Please select at least one option" }]}
                  >
                    <Checkbox.Group
                      options={[
                        {
                          label: "PATIENT",
                          value: "PATIENT",
                        },
                        {
                          label: "CLINIC",
                          value: "CLINIC",
                        },
                      ]}
                      //   onChange={onChange}
                    />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="CAN WE ADD AS A NEW MATERIAL?"
                    name="isNewMaterial"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                    rules={[{ required: true, message: "Please select an option" }]} 
                  >
                    <Radio.Group onChange={(e) => setDisabled(e.target.value) }>
                      <Radio value={1}>Yes</Radio>
                      <Radio value={2}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="ENTER NEW MATERIAL VALUE IN SAR"
                    name="materialValue"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                    rules={[
                      { required: disabled, message: "Please enter the material value" },
                    ]}
                  >
                    <Input size="large" onKeyDown={(event) => {
                          if (!/[0-9]/.test(event.key) && event.key !== "Backspace") {
                            event.preventDefault();
                          }
                        }} />
                  </Form.Item>
                </Col>
                {/* <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="UPLOAD DOCUMENT"
                    name="uploadedFile"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                    rules={[
                      { required: true, message: "Please upload a document" },
                    ]}
                  >
                    <Upload
                      customRequest={() => {}}
                      fileList={[]}
                      className="document_upload"
                    >
                      <Button icon={<UploadOutlined />}>Upload</Button>
                    </Upload>
                  </Form.Item>
                </Col> */}
                <Col span="24" className="mt_15">
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    className="w_100"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Drawer>
        );
      }}
    </Observer>
  )
};

export default EditNewDocumentDrawer;
