import { message } from "antd";
import { useState } from "react";

const getBase64 = (img) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(img);
    reader.onload = () => resolve({ base64: reader.result, success: true });
    reader.onerror = (error) => reject({ base64: null, success: false });
  });
};

export const useUploadMaterial = () => {
  const [imageUrl, setImageUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [fileObject, setFileObject] = useState({});
  const [fileList, setFileList] = useState("");

  const handleMaterialUpload = async (info) => {
    try {
      setImageUrl("");
      setLoading(true);

      setFileList(URL.createObjectURL(info.file.originFileObj)); // Create URL for video preview

      if (info.fileList.length > 0) {
        const [uploadedFile] = info.fileList;
        const fileName = uploadedFile.name;

        getBase64(uploadedFile.originFileObj).then(
          async ({ base64, success }) => {
            setFileObject(uploadedFile.originFileObj);
            setImageUrl(base64);
            setLoading(false);
          }
        );
      }
    } catch (error) {
      message.error("Failed to upload file");
    }
  };

  return {
    handleMaterialUpload,
    base64: imageUrl,
    loading,
    fileObject,
    fileList,
    setFileList,
  };
};
