import { isNull, isString, isUndefined, isNumber, isBoolean, isEmpty } from "lodash";
import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import localStore from 'store2';
import JSURL from 'jsurl';
import { mainStore } from "../store/mainStore";

export const getValidDataFromResponse = (response, isRequired = true, disableCatchErrorMessage = true, isWarning = true) => {
    try {
        if (isValidObject(response, ["success", "message", "data"])) {
            const { message, data, success, isLogout = false } = response;

            if (isLogout) {
                throw new Error("Go For Logout.");
            }

            if (success && isRequired) {
                sendNotification({ message: message, notificationType: "default" });
            } else if (!success && isWarning) {
                sendNotification({ message: message, notificationType: "warning" });
            }
            return { data, error: !success };
        }
        throw new Error("Not a valid response");
    } catch (error) {
        const isValidKey = isValidStoredKey(['uid']);
        const { isLogout = false } = response;
        if (isValidKey && isLogout) {
            const { uid } = getLocalStorageItems({ get: true, key: 'uid' });
            // deleteRequest(`/user/logout/${uid}`);
            localStore.clear();
        }
        // if (disableCatchErrorMessage) {
        // sendNotification({ message: "Something went wrong.", notificationType: "error" });
        // }
        return { data: [], error: true }
    }
}

export const sendNotification = ({ notificationType, message, duration }) => {
    const options = {
        progress: 0,
        autoClose: duration || 3000
    };

    if (notificationType === 'error') {
        toast.error(message, options);
    } else if (notificationType === 'default') {
        toast(message, options);
    } else if (notificationType === 'warning') {
        toast.warning(message, options);
    } else if (notificationType === 'success') {
        toast.success(message, options);
    }
}

export const isValidArray = (arr) => {
    return arr && Array.isArray(arr) && arr.length > 0;
}

export const isValidObject = (obj, keys) => {
    if (isValidArray(keys)) {
        const newObjKeys = isValidObject(obj, []) ? Object.keys(obj) : [];

        if (!isValidArray(newObjKeys)) {
            return false;
        }

        let isValid = keys.length;
        keys.forEach(key => {
            if (newObjKeys.includes(key)) {
                isValid -= 1;
            }
        });
        return isValid === 0;
    }
    return obj && Object.keys(obj).length > 0;
}

export const getAllRequiredHeaders = () => {

    const { loginStore } = mainStore

    const token = loginStore.loginUserToken;
    return {
        "Authorization": `Bearer ${token}`,
    };
}

export const setLocalStorageItems = ({ set = false, setAll = false, item = null, items = {} }) => {
    if (set) {
        const { key, value } = item;
        localStore.set(key, value);
    } else if (setAll) {
        localStore.setAll(items);
    }
}

export const getLocalStorageItems = ({ get = false, getAll = false, key = null, keys = {} }) => {
    if (get) {
        const value = localStore.get(key);
        return { [key]: value };
    } else if (getAll) {
        return localStore.getAll(keys);
    }
    return null;
}

export const isValidStoredKey = (keys = []) => {
    let isValidKey = true;

    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];

        if (localStore.has(key)) {
            const value = localStore.get(key);
            if (isUndefined(value) && isNull(value)) {
                isValidKey = false;
                break;
            } else if (isString(value) && value.length === 0) {
                isValidKey = false;
                break;
            }
        } else {
            isValidKey = false;
            break;
        }
    }

    return isValidKey;
}

export const trimString = (str, removedStr, extraRemoveLength = 0) => {
    if (isString(str) && str.length > 0) {
        const strLength = str.search(removedStr) - extraRemoveLength;
        const latestStr = str.slice(0, strLength);
        return latestStr;
    }
    return "";
}

export const validBodyFieldsForUpdate = (obj, skipKeys) => {
    let newObj = {};

    if (isValidObject(obj)) {
        const keys = Object.keys(obj);

        for (let index = 0; index < keys.length; index++) {
            let isValid = true;
            const value = obj[keys[index]];
            if (!skipKeys.includes(keys[index])) {
                if (!isNumber(value) && !isBoolean(value) && isEmpty(value)) {
                    isValid = false;
                } else if (!isNumber(value) && !isBoolean(value) && isUndefined(value)) {
                    isValid = false;
                } else if (isString(value) && (value.length === 0 || value.charAt(0) === ' ')) {
                    isValid = false;
                } else if (typeof value === 'object') {
                    if (!(isValidArray(value) || isValidObject(value))) {
                        isValid = false;
                    }
                } else if (isNumber(value) && value === 0) {
                    isValid = false;
                } else if (isNull(value)) {
                    isValid = false;
                }
            }

            if (isValid) {
                newObj[keys[index]] = value;
            }
        }
    }
    return { ...newObj };
}

export const useQueryParam = (key) => {
    let [searchParams, setSearchParams] = useSearchParams();
    let paramValue = searchParams.get(key);

    let value = useMemo(() => JSURL.parse(JSURL.stringify(paramValue)), [paramValue]);

    let setValue = useCallback(
        (newValue) => {
            let newSearchParams = new URLSearchParams(searchParams);
            newSearchParams.set(key, JSURL.stringify(newValue));
            setSearchParams(newSearchParams);
        },
        [key, searchParams, setSearchParams]
    );

    return [value, setValue];
}

export const isValidURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
}

export const checkValidUrlPath = (pathname = [], key) => {
    const paths = pathname.split('/');
    let isValid = false;

    paths?.forEach(u => {
        if (u === key) {
            isValid = true;
        }
    });

    return isValid;
}

export const isBase64 = (str) => {
    if (str === '' || str.trim() === '') { return false; }
    try {
        return btoa(atob(str)) === str;
    } catch (err) {
        return false;
    }
}
