export const DisordersIcon = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_583_767)">
        <mask
          id="mask0_583_767"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="50"
          height="50"
        >
          <path
            d="M48.9999 49V1.00014H1V49H48.9999Z"
            fill="white"
            stroke="white"
            stroke-width="2"
          />
        </mask>
        <g mask="url(#mask0_583_767)">
          <path
            d="M14.2305 42.2069C14.621 42.1521 15.0184 42.0961 15.417 42.0421C19.6385 41.4699 21.5112 45.0748 22.7154 48.041C23.086 48.954 24.0752 49.4509 25.0292 49.205L39.5867 45.4524C40.7182 45.1608 41.3457 43.9543 40.9394 42.8587C39.7281 39.5938 38.3489 34.8062 40.1568 33.1699C46.0259 27.8583 47.0594 24.904 47.0594 18.0142C47.0594 12.8045 44.9244 8.93378 41.7105 6.1916"
            stroke="#27A9E0"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M38.9099 4.22348C34.7151 1.79536 29.5072 0.732568 24.8042 0.732568C15.8595 0.732568 7.72978 5.44749 7.133 14.5968C7.0165 16.3831 6.24648 17.2463 6.07255 17.9932C5.86123 18.9006 6.82597 19.8324 7.21122 20.7289C7.37265 21.1044 7.34618 21.5372 7.14599 21.8936C6.35204 23.307 5.35566 24.2683 4.16377 25.1859C3.46944 25.7205 2.93956 26.1983 2.94141 27.0307C2.94356 28.0016 3.55147 28.6359 4.85605 29.0371C7.35536 29.8057 4.33369 31.765 4.33369 31.765C4.33369 31.765 3.91504 32.3678 4.59912 32.9132C5.40586 33.5565 5.77138 34.0329 5.77138 34.0329C4.53701 34.9228 5.20781 35.9058 5.20781 35.9058C5.20781 35.9058 5.84794 36.0423 6.3456 36.3869C6.59462 36.5592 6.74784 36.9681 6.57529 37.2767C6.25478 37.8494 5.81806 38.5353 6.11591 39.7455C6.57529 41.6114 8.14081 42.3018 9.71141 42.5588C10.0081 42.6074 10.3903 42.6152 10.8358 42.5945"
            stroke="#27A9E0"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokelinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M33.6698 27.8475C34.1484 28.0869 34.6579 28.2588 35.1837 28.3583C38.0403 28.9023 40.8855 27.1823 41.7349 24.4012C42.2106 22.8437 41.9948 21.2549 41.2745 19.948C42.1859 19.3083 42.8826 18.3532 43.1794 17.1897C43.8389 14.6039 42.2773 11.973 39.6915 11.3135C39.6794 11.3104 39.6673 11.3081 39.6551 11.3051C39.3615 9.45802 38.0039 7.87092 36.0765 7.37932C34.624 7.00891 33.1575 7.33928 32.034 8.15812C31.324 6.70608 30.0053 5.56311 28.3163 5.13225C26.2481 4.60471 24.1558 5.2804 22.7782 6.71975C22.3685 6.44571 21.915 6.24356 21.4372 6.12199C19.1457 5.53752 16.8162 6.89328 16.181 9.15792C14.0685 9.3381 12.2051 10.8266 11.6515 12.9969C11.3616 14.1339 11.4776 15.2785 11.907 16.2795C11.4139 16.8466 11.0722 17.5292 10.9136 18.2638C10.4173 20.5449 11.7936 22.8576 14.0354 23.5087C15.3242 23.883 16.6355 23.6535 17.6782 22.9939C18.2763 22.6157 18.9939 22.5169 19.6386 22.7019"
            stroke="#27A9E0"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23.5527 25.0385C23.7741 25.115 23.9985 25.1823 24.2255 25.2401C24.4132 25.2879 24.6026 25.3291 24.7932 25.3638C25.4319 26.3301 26.4181 27.0711 27.6288 27.38C28.2907 27.5498 28.9812 27.5766 29.6544 27.4588"
            stroke="#27A9E0"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M31.4432 35.0553C31.1221 36.085 30.0269 36.6594 28.9971 36.3383C27.9673 36.0171 27.393 34.9219 27.7141 33.8922C28.0354 32.8624 29.1304 32.288 30.1602 32.6092C31.1899 32.9304 31.7644 34.0255 31.4432 35.0553Z"
            stroke="#27A9E0"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.6777 34.5215C19.6777 35.6001 20.5521 36.4746 21.6309 36.4746C22.7096 36.4746 23.584 35.6001 23.584 34.5215C23.584 33.4428 22.7096 32.5684 21.6309 32.5684C20.5521 32.5684 19.6777 33.4428 19.6777 34.5215Z"
            stroke="#27A9E0"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M32.1377 19.5592L34.1258 13.1846C34.447 12.1548 35.5421 11.5804 36.5719 11.9016C37.6017 12.2228 38.1761 13.3179 37.8549 14.3477L33.4392 28.5051C33.118 29.5349 32.0229 30.1093 30.9931 29.7881C29.9633 29.4669 29.389 28.3718 29.7102 27.342L31.1185 22.8265"
            stroke="#27A9E0"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.2376 10.9576C25.5656 11.2719 28.2337 14.0077 28.4092 17.3907C28.5074 19.2815 27.8448 21.0777 26.5434 22.4484C25.7988 23.2326 24.8789 23.8227 23.874 24.1799C23.7789 24.2134 23.6964 24.2756 23.6381 24.3579C23.5798 24.4402 23.5484 24.5385 23.5482 24.6394V27.7145C23.5482 28.8168 22.635 29.7058 21.5239 29.6663C20.4655 29.6287 19.642 28.7268 19.642 27.6677V22.6344C19.642 21.5609 20.5028 20.6618 21.5763 20.6684C22.3906 20.6735 23.1493 20.35 23.7105 19.7588C24.2671 19.1726 24.5505 18.4036 24.5084 17.5932C24.4296 16.0768 23.1768 14.865 21.6562 14.8344C21.6363 14.8339 21.6164 14.8337 21.5966 14.8337C20.1439 14.8337 18.9069 15.9022 18.7059 17.3418C18.6881 17.4707 18.6787 17.6006 18.6778 17.7307C18.6717 18.6252 18.0909 19.428 17.2226 19.6426C15.9286 19.9625 14.7715 18.992 14.7715 17.751C14.7715 17.4337 14.7936 17.1142 14.8373 16.8017C15.1741 14.388 16.7498 12.4198 18.8655 11.4962"
            stroke="#27A9E0"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_583_767">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export const MaterialsIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_583_813)">
        <mask
          id="mask0_583_813"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="50"
          height="50"
        >
          <path
            d="M48.9999 49V1.00014H1V49H48.9999Z"
            fill="white"
            stroke="white"
            strokeWidth="2"
          />
        </mask>
        <g mask="url(#mask0_583_813)">
          <path
            d="M37.9379 30.7802L49.0233 36.5483L24.9999 49.0482L0.976562 36.5483L12.0618 30.7803"
            stroke="#27A9E0"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M6.98242 28.125L17.9539 22.4162"
          stroke="#27A9E0"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.9883 31.2375L23.9305 25.544"
          stroke="#27A9E0"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.1406 34.2936L42.871 21.9068"
          stroke="#27A9E0"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <mask
          id="mask1_583_813"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="50"
          height="50"
        >
          <path
            d="M48.9999 49V1.00014H1V49H48.9999Z"
            fill="white"
            stroke="white"
            strokeWidth="2"
          />
        </mask>
        <g mask="url(#mask1_583_813)">
          <path
            d="M38.0014 19.2774L49.0233 25.0124L24.9999 37.5123L0.976562 25.0124L12.0618 19.2445"
            stroke="#27A9E0"
            stroke-Width="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M40.9094 9.25474L49.0233 13.4766L24.9999 25.9766L0.976562 13.4766L24.9999 0.976635L33.1135 5.1983"
            stroke="#27A9E0"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_583_813">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
