import { Button, Col, Drawer, Popconfirm, Row, message } from "antd";
import { Observer } from "mobx-react";
import React, { useEffect } from "react";
import { useMainStore } from "../../store/mainStore";
import {
  PlayCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { toJS } from "mobx";

const VideoMaterialDrawer = () => {
  const mainStore = useMainStore();
  const { disorderStore } = mainStore;

  useEffect(() => {
    const { singleDisorderData, getMaterial } = disorderStore;
    const [{ _id }] = singleDisorderData;

    if (!_id) return;

    const newBody = {
      disorderId: _id,
      materialType: "VIDEO",
    };

    getMaterial(newBody).then((res) => console.log("res", res));
  }, [disorderStore.singleDisorderData]);

  const confirm = async (e) => {
    const { getMaterial, deleteAMaterial, singleDisorderData } = disorderStore;
    const [{ _id }] = singleDisorderData;
    // _id
    deleteAMaterial(e._id)
      .then(() => {
        const newBody = {
          disorderId: _id,
          materialType: "VIDEO",
        };
        getMaterial(newBody);
        message.success("Material deleted successfully");
      })
      .catch(() => {
        message.error("Failed to delete material");
      });
    // message.success("Click on Yes");
  };

  return (
    <Observer>
      {() => {
        const {
          setViewSingleVideoMaterialModalStatus,
          setAddVideoMaterialModalStatus,
          videoMaterialModalStatus,
          setVideoMaterialModalStatus,
          videoMaterial,
          setSingleVideoMaterial,
          setIsUpdatingVideoMaterial,
        } = disorderStore;

        return (
          <Drawer
            title={
              <>
                <div className="d_flex justify_content_between align_items_center">
                  <div>Videos</div>
                  <Button
                    type="primary"
                    onClick={() => setAddVideoMaterialModalStatus(true)}
                  >
                    Add Video
                  </Button>
                </div>
              </>
            }
            width={750}
            onClose={() => setVideoMaterialModalStatus()}
            open={videoMaterialModalStatus}
            maskClosable={false}
          >
            {/* <Row>
              <Col span={24}>
                <div className="video_drawer_title">
                  <h3 className="title">About The Cource</h3>
                  <p className="sub_title">6 Days Training Program.</p>
                  <p className="description">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </p>
                </div>
              </Col>
            </Row> */}
            <Row gutter={24}>
              {videoMaterial.map((item) => {
                return (
                  <Col span={12}>
                    <div className="materials_inner_card">
                      <div className="content">
                        <h3 className="title">{item.name}</h3>
                        <p className="para">{item.description}</p>
                      </div>
                      <div className="dashboard_action">
                        <Button
                          className="action_btn mr_5"
                          type="primary"
                          onClick={() => {
                            setSingleVideoMaterial(item);
                            setViewSingleVideoMaterialModalStatus(true);
                          }}
                        >
                          <PlayCircleOutlined />
                        </Button>
                        {/* <Button
                        type="primary"
                        className="action_btn mr_5"
                        onClick={() => {
                          setSingleVideoMaterial(item);
                          setAddVideoMaterialModalStatus(true);
                          setIsUpdatingVideoMaterial(true)
                        }}
                      >
                        <EditOutlined />
                      </Button> */}
                        <Popconfirm
                          title="Delete the task"
                          description="Are you sure to delete this task?"
                          onConfirm={() => confirm(item)}
                          // onCancel={cancel}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button className="action_btn delete mr_5">
                            <DeleteOutlined />
                          </Button>
                        </Popconfirm>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Drawer>
        );
      }}
    </Observer>
  );
};

export default VideoMaterialDrawer;
