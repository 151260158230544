import React, { useEffect } from "react";
import { Button, Col, Form, Input, Row, message } from "antd";
import Logo from "../assets/logo_dark.svg";
import LoginImg from "../assets/login.svg";
import { Link, useNavigate } from "react-router-dom";
import "../App.css";
import {
  MailOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { useMainStore } from "../store/mainStore";
import { toJS } from "mobx";

const Login = () => {
  const navigate = useNavigate();

  const mainStore = useMainStore();
  const { loginStore } = mainStore;

  const handleUserLogin = async (values) => {
    

    loginStore.userLogin(values).then(({ success, data }) => {
      if (success) {
        navigate("/users");
      }
    }).catch((error) => {
      message.error(error.message);
    });
  };

  // const [loading, setLoading] = useState(false);
  // const [login, setLogin] = useState(false);
  // const [error, setError] = useState("");
  // const [msg, setMsg] = useState("");
  // const navigate = useNavigate();

  // useEffect(() => {
  //   let login = localStorage.getItem("login");
  //   if (login) {
  //     navigate("/dashboard");
  //   }
  //   let loginStatus = localStorage.getItem("loginStatus");
  //   if (loginStatus) {
  //     setError(loginStatus);
  //     setTimeout(function () {
  //       localStorage.clear();
  //       window.location.reload();
  //     }, 3000);
  //   }
  //   setTimeout(function () {
  //     setMsg("");
  //   }, 5000);
  // }, [msg]);

  /* eslint-disable no-template-curly-in-string */
  // const validateMessages = {
  //   required: "${label} is required!",
  //   types: {
  //     email: "${label} is not a valid email!",
  //     password: "${label} enter min 5 characters!",
  //   },
  // };
  /* eslint-enable no-template-curly-in-string */

  // const handleLogin = async (values) => {
  //   setLoading(true);
  //   try {
  //     const endpoint = `${process.env.REACT_APP_API_ENDPOINT}/login`;
  //     const data = values;

  //     const response = await axios.post(endpoint, data);

  //     if (response.status === 200) {
  //       console.log(response.data);
  //       const user = response.data;

  //       localStorage.setItem("userInfo", JSON.stringify(user));
  //       navigate("/dashboard");
  //       setLogin(false);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setLogin(false);
  //     setLoading(false);
  //   }
  // };

  return (
    <>
      <div className="main_wrapper">
        <div className="login_container">
          <Row gutter={20} align={"middle"}>
            <Col span="12">
              <div className="login_img_card">
                <img src={LoginImg} alt="login_img" className="login_img" />
              </div>
            </Col>
            <Col span="12">
              <div className="credentials_card">
                <div className="card">
                  <img src={Logo} alt="logo" width={200} />
                  <h1 className="title">Welcome back!</h1>
                  <p className="para">Please enter your email</p>

                  <Form onFinish={handleUserLogin}>
                    <Form.Item name={"email"}>
                      <Input
                        size="large"
                        placeholder="Email Address"
                        prefix={<MailOutlined />}
                        sizes={"large"}
                        className="mb_10"
                      />
                    </Form.Item>

                    <Form.Item name={"password"}>
                      <Input.Password
                        placeholder="Password"
                        className="password mb_5"
                        prefix={<LockOutlined />}
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                      />
                    </Form.Item>
                    <Link
                      to={"/forgot-password"}
                      className="text_left w_100 d_inline_block fs_14"
                    >
                      Forgot Password
                    </Link>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login_btn"
                    >
                      Login
                    </Button>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default Login;
