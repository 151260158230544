import { Button, Drawer, Image, Row } from "antd";
import { Observer } from "mobx-react";
import React from "react";
import { useMainStore } from "../../store/mainStore";

const ViewDocumentMaterial = () => {
  const mainStore = useMainStore();
  const { disorderStore } = mainStore;

  return (
    <Observer>
      {() => {
        const {
          setViewDocumentDrawerStatus,
          viewDocumentDrawerStatus,
          singleDocumentMaterial,
        } = disorderStore;
        console.log("viewDocumentDrawerStatus", viewDocumentDrawerStatus);

        return (
          <Drawer
            title="Document Material Preview"
            width={500}
            onClose={() => setViewDocumentDrawerStatus()}
            open={viewDocumentDrawerStatus}
            maskClosable={false}
          >
            <Row>
              <div className="subscription_details">
                <div className="label">DOCUMENT NAME</div>
                <div className="value">{singleDocumentMaterial?.name}</div>
              </div>
              <div className="subscription_details">
                <div className="label">DESCRIPTION</div>
                <div className="value">
                  {singleDocumentMaterial?.description}
                </div>
              </div>
              <div className="subscription_details">
                <div className="label">MATERIAL ACCESS</div>
                <div className="value">
                  {singleDocumentMaterial?.access?.join(", ")}
                </div>
              </div>
              <div className="subscription_details">
                <div className="label">CAN WE ADD AS A NEW MATERIAL?</div>
                <div className="value">
                  {singleDocumentMaterial?.isNewMaterial ? "Yes" : "No"}
                </div>
              </div>
              <div className="subscription_details">
                <div className="label">ENTER NEW MATERIAL VALUE</div>
                <div className="value">
                  {singleDocumentMaterial?.materialValue}
                </div>
              </div>
              <div className="subscription_details">
                <div className="label">Document</div>
                <div className="value">
                  <Button>
                    <a
                      href={`https://api.scalegreat.in/${singleDocumentMaterial?.uploadedFile}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Document
                    </a>
                  </Button>
                </div>
              </div>
            </Row>
          </Drawer>
        );
      }}
    </Observer>
  );
};

export default ViewDocumentMaterial;
