import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  Input,
  Radio,
  Row,
  Upload,
  message,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useState } from "react";
import { useMainStore } from "../../store/mainStore";
import { Observer } from "mobx-react";

import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import { useUploadMaterial } from "../../hooks/useUploadMaterial";
import { toJS } from "mobx";

const AddNewVideoDrawer = () => {
  const mainStore = useMainStore();
  const { disorderStore } = mainStore;

  const [addVideoMaterialForm] = Form.useForm();

  const [disabled, setDisabled] = React.useState(false);

  const {
    handleMaterialUpload,
    base64,
    loading,
    fileObject,
    fileList,
    setFileList,
  } = useUploadMaterial();

  const handleCreateVideoMaterial = async (values) => {
    const {
      singleDisorderData,
      addNewVideoMaterial,
      setIsAddingMaterial,
      setAddVideoMaterialModalStatus,
      getMaterial,
      isUpdatingVideoMaterial,
      updateAMaterial,
      singleVideoMaterial,
    } = disorderStore;
    try {
      if (!isUpdatingVideoMaterial) {
        const [{ _id }] = singleDisorderData;
        setIsAddingMaterial(true);

        const formData = new FormData();

        formData.append("disorderId", _id);
        formData.append("name", values.name);
        formData.append("description", values.description);
        formData.append("materialType", "VIDEO");
        formData.append("access", values.access.join(","));
        formData.append("isNewMaterial", values.isNewMaterial ? true : false);
        formData.append("materialValue", values.materialValue);
        formData.append("uploadedFile", fileObject);
        formData.append("status", true);

        const { success, data } = await addNewVideoMaterial(formData);

        if (!success) {
          setIsAddingMaterial();
          addVideoMaterialForm.resetFields();
          return message.error("Something went wrong");
        }

        getMaterial({
          disorderId: _id,
          materialType: "VIDEO",
        });

        setIsAddingMaterial();
        addVideoMaterialForm.resetFields();
        setFileList("");
        setAddVideoMaterialModalStatus();
        message.success("Material added successfully");
      } else {
        const newBody = {
          ...values,
          isNewMaterial: values.isNewMaterial ? true : false,
          materialId: singleVideoMaterial._id,
        };

        updateAMaterial(newBody)
          .then(() => {
            message.success("Material updated successfully");
            const [{ _id }] = singleDisorderData;

            if (!_id) return;

            const newBody = {
              disorderId: _id,
              materialType: "VIDEO",
            };

            getMaterial(newBody);
            setAddVideoMaterialModalStatus(false);
            addVideoMaterialForm.resetFields();
            setFileList("");
          })
          .catch(() => {
            message.error("Failed to update material");
          });
      }
    } catch (error) {
      setIsAddingMaterial();
      addVideoMaterialForm.resetFields();
      message.error("Something went wrong");
    }
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const onChange = async (info) => {
    await handleMaterialUpload(info);

    if (info.file.status === "done") {
      addVideoMaterialForm.setFieldsValue({
        uploadedFile: fileObject, // Update form field with the uploaded file
      });
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  return (
    <Observer>
      {() => {
        const {
          setAddVideoMaterialModalStatus,
          addVideoMaterialModalStatus,
          isAddingMaterial,
          isUpdatingVideoMaterial,
          setIsUpdatingVideoMaterial,
          singleVideoMaterial,
        } = disorderStore;

        const { uploadedFile, isNewMaterial, ...rest } = singleVideoMaterial;

        return (
          <Drawer
            title={
              !isUpdatingVideoMaterial
                ? "Create a Video Material"
                : "Update Video Material"
            }
            width={500}
            onClose={() => {
              setAddVideoMaterialModalStatus();
              setIsUpdatingVideoMaterial(false);
            }}
            open={addVideoMaterialModalStatus}
            maskClosable={false}
          >
            <Form
              onFinish={handleCreateVideoMaterial}
              form={addVideoMaterialForm}
              initialValues={
                isUpdatingVideoMaterial
                  ? { ...rest, isNewMaterial: isNewMaterial ? 1 : 0 }
                  : {}
              }
            >
              <Row>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="VIDEO NAME"
                    name="name"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                    rules={[
                      { required: true, message: "Please enter a video name" },
                    ]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="DESCRIPTION"
                    name="description"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                    rules={[
                      { required: true, message: "Please enter a description" },
                    ]}
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="MATERIAL ACCESS"
                    name="access"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                    rules={[
                      {
                        required: true,
                        message: "Please select at least one option",
                      },
                    ]}
                  >
                    <Checkbox.Group
                      options={[
                        {
                          label: "PATIENT",
                          value: "PATIENT",
                        },
                        {
                          label: "CLINIC",
                          value: "CLINIC",
                        },
                      ]}
                      //   onChange={onChange}
                    />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="CAN WE ADD AS A NEW MATERIAL?"
                    name="isNewMaterial"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                    rules={[
                      { required: true, message: "Please select an option" },
                    ]}
                  >
                    <Radio.Group onChange={(e) => setDisabled(e.target.value)}>
                      <Radio value={1}>Yes</Radio>
                      <Radio value={0}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="ENTER NEW MATERIAL VALUE IN SAR"
                    name="materialValue"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                    rules={[
                      {
                        required: disabled,
                        message: "Please enter the material value",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      disabled={!disabled}
                      onKeyDown={(event) => {
                        if (
                          !/[0-9]/.test(event.key) &&
                          event.key !== "Backspace"
                        ) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                {!isUpdatingVideoMaterial && (
                  <Col span="24">
                    <Form.Item
                      layout="vertical"
                      label="UPLOAD VIDEOS"
                      name="uploadedFile"
                      labelCol={{
                        span: 24,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      className="mb_10"
                      rules={[
                        { required: true, message: "Please upload a video" },
                      ]}
                    >
                      <Upload
                        name="avatar"
                        accept="video/*"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        customRequest={({ file, onSuccess }) => {
                          setTimeout(() => {
                            onSuccess("ok");
                          }, 0);
                        }}
                        onChange={onChange}
                      >
                        {/* {imageUrl ? (
                          <img
                            src={imageUrl}
                            alt="avatar"
                            style={{
                              width: "100%",
                            }}
                          />
                        ) : ( */}
                        {uploadButton}
                        {/* )} */}
                      </Upload>
                      {fileList && (
                        <div style={{ marginTop: 20 }}>
                          <video width="50%" controls autoPlay>
                            <source src={fileList} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      )}
                    </Form.Item>
                  </Col>
                )}
                <Col span="24" className="mt_15">
                  <Button
                    loading={isAddingMaterial}
                    type="primary"
                    htmlType="submit"
                    size="large"
                    className="w_100"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Drawer>
        );
      }}
    </Observer>
  );
};

export default AddNewVideoDrawer;
