import { Observer } from "mobx-react";
import React, { useEffect } from "react";
import { useMainStore } from "../../store/mainStore";
import { Button, Col, Drawer, Popconfirm, Row, message } from "antd";
import {
  PlayCircleOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { toJS } from "mobx";

const DocumentMaterialDrawer = (props) => {
  const mainStore = useMainStore();
  const { disorderStore } = mainStore;

  useEffect(() => {
    const { singleDisorderData, getMaterial } = disorderStore;
    const [{ _id }] = singleDisorderData;

    if (!_id) return;

    const newBody = {
      disorderId: _id,
      materialType: "DOCUMENT",
    };

    getMaterial(newBody).then((res) => console.log("res", res));
  }, [disorderStore.singleDisorderData]);

  // Delete popover
  const confirm = async (e) => {
    const { getMaterial, deleteAMaterial, singleDisorderData } = disorderStore;
    const [{ _id }] = singleDisorderData;
    // _id
    deleteAMaterial(e._id)
      .then(() => {
        const newBody = {
          disorderId: _id,
          materialType: "DOCUMENT",
        };
        getMaterial(newBody);
        message.success("Material deleted successfully");
      })
      .catch(() => {
        message.error("Failed to delete material");
      });
    // message.success("Click on Yes");
  };
  const cancel = (e) => {
    console.log(e);
    // message.error("Click on No");
  };

  return (
    <Observer>
      {() => {
        const {
          setViewDocumentDrawerStatus,
          setSingleDocumentMaterial,
          documentMaterial,
          documentMaterialDrawerStatus,
          setDocumentMaterialDrawerStatus,
          setAddDocumentMaterialDrawerStatus,
          setEditDocumentMaterialModalStatus,
        } = disorderStore;
        return (
          <Drawer
            title={
              <>
                <div className="d_flex justify_content_between align_items_center">
                  <div>Document</div>
                  <Button
                    type="primary"
                    onClick={() => setAddDocumentMaterialDrawerStatus(true)}
                  >
                    Add Document
                  </Button>
                </div>
              </>
            }
            width={750}
            onClose={() => setDocumentMaterialDrawerStatus()}
            open={documentMaterialDrawerStatus}
            maskClosable={false}
          >
            {/* <Row>
              <Col span={24}>
                <div className="video_drawer_title">
                  <h3 className="title">About The Cource</h3>
                  <p className="sub_title">6 Days Training Program.</p>
                  <p className="description">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </p>
                </div>
              </Col>
            </Row> */}
            <Row gutter={24}>
              {documentMaterial?.map((item, index) => (
                <Col key={index} span={12}>
                  <div className="materials_inner_card">
                    <div className="content">
                      <h3 className="title">{item?.name}</h3>
                      <p className="para">{item?.description}</p>
                    </div>
                    <div className="dashboard_action">
                      <Button
                        className="action_btn view mr_5"
                        onClick={() => {
                          setSingleDocumentMaterial(item);
                          setViewDocumentDrawerStatus(true);
                        }}
                      >
                        <PlayCircleOutlined />
                      </Button>
                      {/* <Button
                        type="primary"
                        className="action_btn mr_5"
                        // onClick={props.EditShowDocDrawer}
                        onClick={() => {
                          setSingleDocumentMaterial(item);
                          setEditDocumentMaterialModalStatus(true);
                        }}
                      >
                        <EditOutlined />
                      </Button> */}
                      <Popconfirm
                        title="Delete the task"
                        description="Are you sure to delete this task?"
                        onConfirm={() => confirm(item)}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button className="action_btn delete mr_5">
                          <DeleteOutlined />
                        </Button>
                      </Popconfirm>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Drawer>
        );
      }}
    </Observer>
  );
};

export default DocumentMaterialDrawer;
