import styled from "styled-components";
import { Card, Form } from "antd";
import { Content } from "antd/es/layout/layout";

//Dashboard page

export const Maincontainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow-y: hidden;
`;
export const Cardbox = styled(Card)`
  background-color: #35a8df;
  width: 30%;
  @media screen and (max-width: 768px) {
    width: 50%;
  }
`;
export const Logoimg = styled.div`
  display: flex;
  justify-content: center;
`;
export const Formfield = styled(Form)`
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 40px;
`;
// export const LineChartstyle = styled(Line)`
//   width: 100%;
//   height: 100%;
//   @media screen and (max-width: 768px) {
//     width: 90%;
//   }
// `;
export const Chart = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 1%;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;
export const LineC = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  width: 57%;
  padding-top: 2%;
  padding-left: 3%;
  padding-bottom: 4%;
  border-radius: 10px;
  @media screen and (max-width: 768px) {
    width: 95%;
  }
`;
export const PieC = styled.div`
  width: 40%;
  padding: 1.5%;
  border-radius: 10px;
  background-color: #ffffff;
  @media screen and (max-width: 768px) {
    width: 95%;
  }
`;
export const Listtype = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 1%;
  padding-left: 2%;
  padding-right: 2%;
  margin-bottom: 10px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;
export const Disorders = styled.div`
  width: 49%;
  height: 40%;
  border-radius: 10px;
  background-color: #ffffff;
  padding-top: 2%;
  padding-left: 3%;
  padding-right: 4%;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    width: 95%;
  }
`;
export const Materials = styled.div`
  width: 49%;
  height: 40%;
  border-radius: 10px;
  background-color: #ffffff;
  padding-top: 2%;
  padding-left: 3%;
  padding-right: 4%;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    width: 95%;
  }
`;

// Materials page
export const Maincontent = styled(Content)`
  background-color: #fff;
  padding-left: 30px;
  padding-right: 30px;
`;
export const Contentdiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

export const Title = styled.h1`
  font-size: 18px;
  font-weight: 600;
  color: #15192c;
`;

//Subscriptions page
export const Type = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  gap: 20px;
  @media screen and (max-width: 768px) {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    flex-wrap: wrap;
  }
`;
export const Carditem = styled.div`
  background-color: #fff;
  text-align: center;
  border-radius: 10px;
  padding: 40px;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    padding: 30px;
    width: 48%;
  }
`;
export const Paragraph = styled.p`
  font-size: 19px;
  font-weight: 600;
  text-align: center;
  color: #000;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;
export const Duration = styled.p`
  color: white;
  background-color: #35a8df;
  border-radius: 20px;
  padding: 8px;
`;
export const Description = styled.p`
  color: #000;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
export const Clinician = styled.div`
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 60px;
`;

// ActivityTask page
export const Taskcontent = styled(Content)`
  background-color: #fff; //#f8f9fc
  padding-left: 30px;
  padding-right: 30px;
`;
