import axios from 'axios';
import { getAllRequiredHeaders } from './utilities';


const client = axios.create({
	baseURL: 'https://api.scalegreat.in/api/v1',
	headers: {
		// 'Content-Type': 'application/json',
		Accept: 'application/json',
	},
	// transformRequest: [
	// 	function (data) {
	// 		// Do whatever you want to transform the data
	// 		console.log('transformRequest', data);
	// 		return data;
	// 	},
	// ],

	// `transformResponse` allows changes to the response data to be made before
	// it is passed to then/catch
	// transformResponse: [
	// 	function (data) {
	// 		// Do whatever you want to transform the data
	// 		console.log('transformResponse', data);
	// 		return data;
	// 	},
	// ],
});

client.interceptors.response.use((response) => {
	return response;
}, (error) => {
	if(error.response.status === 401){
		window.location.href = "/";
	}

	return error
});

export const getRequest = async (url) => {
	try {
		const res = await client.get(`${url}`, {
			headers: { ...getAllRequiredHeaders() }
		});
		const { data, status } = res;
		if (status === 204) {
			return { response: { success: false, message: 'Something Went Wrong!', data: [] }, error: true };
		}
		return { response: data, error: false };
	} catch (error) {
		return { response: { isLogout: true }, error: true };
	}
};

export const postRequest = async (url, payload = {}) => {
	try {
		const res = await client.post(`${url}`, payload, {
			headers: { ...getAllRequiredHeaders() }
		});

		const { data, status } = res;

		if(!status){
			const { data, status } = res.response;
			return { response: data, error: true };
		}

		if (status === 204) {
			return { response: { success: false, message: 'Something Went Wrong!', data: [] }, error: true };
		}
		return { response: data, error: false };
	} catch (error) {
		return { response: { isLogout: true }, error: true };
	}
};
export const postRequest2 = async (url, payload = {}) => {
	return new Promise(async(resolve, reject) => {
		try {
			const res = await client.post(`${url}`, payload, {
				headers: { ...getAllRequiredHeaders() }
			});
			if(res.response ){
				const { data, status } = res.response;
				if (status === 204 || status >= 400) {
					return resolve({ response: data, error: true });
				}
			}
			const { data, status } = res;
			if (status === 204 || status >= 400) {
				return resolve({ response: data, error: true });
			}
			return resolve( { response: data, error: false });
		} catch (error) {
			return reject ({ response: { isLogout: true }, error: true });
		}
	})
};

export const putRequest = async (url, payload = {}) => {
	try {
		const res = await client.put(`${url}`, payload, {
			headers: { ...getAllRequiredHeaders() }
		});
		const { data, status } = res;
		if (status === 204) {
			return { response: { success: false, message: 'Something Went Wrong!', data: [] }, error: true };
		}
		return { response: data, error: false };
	} catch (error) {
		return { response: { isLogout: true }, error: true };
	}
};

export const patchRequest = async (url, payload = {}) => {
	try {
		const res = await client.patch(`${url}`, payload, {
			headers: { ...getAllRequiredHeaders() }
		});
		const { data, status } = res;
		if (status === 204) {
			return { response: { success: false, message: 'Something Went Wrong!', data: [] }, error: true };
		}
		return { response: data, error: false };
	} catch (error) {
		return { response: { isLogout: true }, error: true };
	}
};

export const deleteRequest = async (url) => {
	try {
		const res = await client.delete(`${url}`, {
			headers: { ...getAllRequiredHeaders() }
		});
		const { data, status } = res;
		if (status === 204) {
			return { response: { success: false, message: 'Something Went Wrong!', data: [] }, error: true };
		}
		return { response: data, error: false };
	} catch (error) {
		return { response: { isLogout: true }, error: true };
	}
};
