import { Link, useNavigate } from "react-router-dom";
import { Content, Header } from "antd/es/layout/layout";
import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Drawer,
  Dropdown,
  Form,
  Image,
  Input,
  Layout,
  Menu,
  Popconfirm,
  Row,
  Select,
  Table,
  message,
} from "antd";
import Logo from "../assets/logo.png";
import AvatarImg from "../assets/avatar.svg";
import Sider from "antd/es/layout/Sider";
import {
  BiSolidUserAccount,
  BiSolidDollarCircle,
  BiSolidCloudUpload,
} from "react-icons/bi";
import { GiHamburgerMenu } from "react-icons/gi";
import {
  LeftOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useMainStore } from "../store/mainStore";
import moment from "moment";
import { sendNotification } from "../utils/utilities";
import { toJS } from "mobx";
import { debounce } from "lodash";

const Users = () => {
  const { RangePicker } = DatePicker;
  const mainStore = useMainStore();
  const { usersStore, globalStore } = mainStore;

  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [isUpdatingUser, setIsUpdatingUser] = useState(false);
  const [userData, setUserData] = useState(usersStore.usersList);

  useEffect(() => {
    setUserData(usersStore.usersList);
  }, [usersStore.usersList]);

  const [addNewUserFormRef] = Form.useForm();

  const getUsersList = debounce(async () => {
    const body = {
      userType: usersStore.userType,
      searchQuery: usersStore.userSearchQuery,
    };

    usersStore
      .getUsersList(body)
      .then(() => {})
      .catch(() => {
        sendNotification({
          type: "error",
          message: "Something went wrong. Please try again",
        });
      });
  }, 1000);

  useEffect(() => {
    getUsersList();
  }, [usersStore.userType, usersStore.userSearchQuery]);

  // useEffect(() => {

  // }, [])

  useEffect(() => {
    globalStore.getCountryList();
    // globalStore.getAllRegionList()
  }, []);

  const items = [
    {
      key: "1",
      label: <Link to="/forgot-password">Forgot Password</Link>,
    },
    {
      key: "2",
      label: <Link to={"/logout"}>Logout</Link>,
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email ID",
      dataIndex: "email",
    },
    {
      title: "Contact Number",
      dataIndex: "mobileNumber",
    },
    {
      title: "Registered On",
      dataIndex: "createdAt",
      render: (_, record) => {
        return <span>{moment(record.createdAt).format("DD-MM-YYYY")}</span>;
      },
    },
    {
      title: "Country",
      dataIndex: "countryName",
    },
    {
      title: "Region",
      dataIndex: "regionName",
    },
    {
      title: "User Type",
      dataIndex: "userType",
      render: (_, record) => {
        return (
          <span>{record.userType == "CLINIC" ? "CLINICIAN" : "PATIENT"}</span>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, record) => {
        return <span>{record.status ? "Active" : "Deactive"}</span>;
      },
    },
    {
      title: "Action",
      render: (_, record) => (
        <>
          <div className="dashboard_action">
            <Button
              className="action_btn edit mr_5"
              onClick={() =>
                AddShowDrawer(() => setIsUpdatingUser(true), record._id)
              }
            >
              <EditOutlined />
            </Button>
            <Popconfirm
              title="Delete the task"
              description="Are you sure to delete this task?"
              onConfirm={() => confirm(record._id)}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button className="action_btn delete mr_5">
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </div>
        </>
      ),
    },
  ];

  // Delete popover
  const confirm = async (userId) => {
    await usersStore
      .deleteUser({ userId })
      .then((res) => {
        getUsersList();
      })
      .catch((err) => {
        console.log("err", err);
        sendNotification({
          type: "error",
          message: "Something went wrong. Please try again",
        });
      });
  };
  const cancel = (e) => {
    console.log(e);
    message.error("Click on No");
  };

  // Add Drawer
  const [addOpen, setAddOpen] = useState(false);
  const AddShowDrawer = (callBack, userId) => {
    if (userId) {
      const data = usersStore.usersList.find((data) => data._id === userId);
      console.log("data", toJS(data));

      usersStore.setSingleUserData(data);
      addNewUserFormRef.setFieldsValue({
        name: data.name,
        email: data.email,
        username: data.username,
        contactNumber: data.mobileNumber,
        country: data.countryName,
        region: data.regionName,
        status: data.status ? "1" : "0",
        userType: data.userType,
      });
    }

    // addNewUserFormRef.resetFields();
    setAddOpen(true);

    callBack();
  };
  const onAddClose = () => {
    addNewUserFormRef.resetFields();
    setAddOpen(false);
  };

  const addNewUser = async (values) => {
    const newBody = {
      ...values,
      country: globalStore.selectedCountry.name,
      status: "1",
    };

    if (!isUpdatingUser) {
      usersStore
        .addNewUser(newBody)
        .then((res) => {
          getUsersList();
        })
        .catch((err) => {
          message.error(err.message);
        })
        .finally(() => onAddClose());
    } else {
      const newBody = {
        ...values,
        userId: usersStore.singleUserData._id,
      };

      usersStore
        .updateUser(newBody)
        .then((res) => {
          console.log("res", res);

          getUsersList();
        })
        .catch((err) => {
          message.error(err.message);
        })
        .finally(() => onAddClose());
    }
  };

  const handleDateChange = (dates) => {
    if (!dates) {
      usersStore.getUsersList(); // Reset to original data or handle accordingly
      return;
    }

    if (dates && dates.length === 2) {
      const [start, end] = dates;

      // Convert to ISO string and then re-parse to ensure consistency
      const startISO = start.toISOString();
      const endISO = end.toISOString();

      const startMoment = moment(startISO);
      const endMoment = moment(endISO);

      const formattedStart = startMoment.format("DD-MM-YYYY");
      const formattedEnd = endMoment.format("DD-MM-YYYY");

      usersStore
        .getUsersList({
          startDate: formattedStart,
          endDate: formattedEnd,
        })
        .then((res) => {
          console.log("res", res);
        });
    }
  };

  return (
    <>
      <Layout style={{ width: "100%", height: "100%" }}>
        <Sider
          style={{
            backgroundColor: "#35A8DF",
            height: "auto",
            // borderRadius: 16,
            marginTop: -10,
            marginLeft: -10,
            marginBottom: -10,
          }}
          trigger={null}
          collapsible
          collapsed={collapsed}
        >
          {!collapsed ? (
            <Image
              width={180}
              src={Logo}
              alt="Logo"
              preview={false}
              style={{
                margin: 10,
                padding: 10,
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          ) : (
            <Image
              width={70}
              src={Logo}
              alt="Logo"
              preview={false}
              style={{
                margin: 10,
                padding: 10,
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          )}
          <Menu
            style={{
              // backgroundColor: "#35A8DF",
              // color: "#fff",
              height: "100vh",
            }}
            mode="inline"
            theme="dark"
            defaultSelectedKeys={["/users"]}
            onClick={(item) => {
              navigate(item.key);
            }}
            items={[
              // {
              //   key: "/dashboard",
              //   icon: <BiHomeAlt2 />,
              //   label: "Dashboard",
              // },
              // {
              //   key: "/materials",
              //   icon: <FaUserSecret />,
              //   label: "Materials / Clinical resources management",
              // },

              {
                key: "/users",
                icon: <BiSolidUserAccount />,
                label: "User management",
              },
              {
                key: "subscription",
                label: "Subscription",
                icon: <BiSolidDollarCircle />,
                children: [
                  {
                    key: "/slp-user-clinicians-subscription",
                    label: "Clinician",
                  },
                  {
                    key: "/caretaker-parents-consumer",
                    label: "Patient",
                  },
                ],
              },
              {
                key: "/materials",
                icon: <BiSolidCloudUpload />,
                label: "Materials Management",
              },
              // {
              //   key: "/",
              //   icon: <FaUsers />,
              //   label: "Activity/Tasks manager",
              // },
              // {
              //   key: "/reports",
              //   icon: <FaUsers />,
              //   label: "Reports",
              // },
              // {
              //   key: "/kpiconfig",
              //   icon: <RiCustomerService2Line />,
              //   label: "KPI configuration",
              // },
              // {
              //   key: "/settings",
              //   icon: <FaTruckFast />,
              //   label: "Profile Settings",
              // },
            ]}
          />
        </Sider>
        <Layout>
          <Header
            style={{
              padding: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              backgroundColor: "#fff",
            }}
          >
            <Button
              type="text"
              icon={collapsed ? <GiHamburgerMenu /> : <LeftOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
              }}
            />
            <div>
              <Dropdown
                menu={{
                  items,
                }}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Avatar
                    src={<img src={AvatarImg} alt="avatar" />}
                    shape="square"
                    size="large"
                  />
                </a>
              </Dropdown>
            </div>
          </Header>
          <Content
            style={{
              backgroundColor: "#F1F5FF",
              padding: 20,
            }}
          >
            <Row>
              <Col span={24}>
                <div className="dashboard_tops">
                  <Row>
                    <Col span={6}>
                      <div className="title">User Management</div>
                    </Col>
                    <Col span={18}>
                      <Row justify={"end"} gutter={5}>
                        <Col span={6}>
                          <Input
                            size="large"
                            placeholder="Search"
                            prefix={<SearchOutlined />}
                            className="mr_5"
                            onChange={(e) =>
                              usersStore.setUserSearchQuery(e.target.value)
                            }
                            style={{ width: "100%" }}
                          />
                        </Col>
                        <Col span={8}>
                          <RangePicker
                            size="large"
                            onChange={handleDateChange}
                          />
                        </Col>
                        <Col span={6}>
                          <Select
                            defaultValue=""
                            size="large"
                            style={{
                              width: "60%",
                            }}
                            onChange={(e) => usersStore.setUserType(e)}
                            options={[
                              {
                                value: "",
                                label: "All",
                              },
                              {
                                value: "CLINIC",
                                label: "Clinician",
                              },
                              {
                                value: "PATIENT",
                                label: "Patient",
                              },
                            ]}
                          />
                        </Col>
                        <Col span={4}>
                          <Button
                            type="primary"
                            size="large"
                            block
                            icon={<PlusOutlined />}
                            onClick={() =>
                              AddShowDrawer(
                                () => setIsUpdatingUser(false),
                                null
                              )
                            }
                          >
                            Add New
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Table
              columns={columns}
              dataSource={userData}
              scroll={{
                x: 800,
              }}
            />
          </Content>
        </Layout>
      </Layout>
      <Drawer
        title={!isUpdatingUser ? "Add New User" : "Update User"}
        width={500}
        onClose={onAddClose}
        open={addOpen}
      >
        <Form
          onFinish={addNewUser}
          // initialValues={
          //   {
          //     name: usersStore.singleUserData?.name,
          //     email: usersStore.singleUserData?.email,
          //     contactNumber: usersStore.singleUserData?.mobileNumber,
          //     country: usersStore.singleUserData?.countryId,
          //     region: usersStore.singleUserData?.regionId,
          //     userType: usersStore.singleUserData?.userType,
          //     status: usersStore.singleUserData?.isDeleted ? "1" : "1",
          //     userType: usersStore.singleUserData?.userType || "PATIENT"
          //   }
          // }
          form={addNewUserFormRef}
        >
          <Row>
            <Col span="24">
              <Form.Item
                layout="vertical"
                label="NAME"
                name="name"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                className="mb_10"
                rules={[
                  {
                    required: true,
                    message: "Please enter your name!",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col span="24">
              <Form.Item
                layout="vertical"
                label="EMAIL ID"
                name="email"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                className="mb_10"
                rules={[
                  {
                    required: true,
                    message: "Please enter your email!",
                  },
                  {
                    type: "email",
                    message: "Please enter a valid email!",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col span="24">
              <Form.Item
                layout="vertical"
                label="USERNAME"
                name="username"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                className="mb_10"
                rules={[
                  {
                    required: true,
                    message: "Please enter your username!",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col span="24">
              <Form.Item
                layout="vertical"
                label="PASSWORD"
                name="password"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                className="mb_10"
                rules={[
                  {
                    required: true,
                    message: "Please enter your password!",
                  },
                ]}
              >
                <Input.Password size="large" />
              </Form.Item>
            </Col>
            <Col span="24">
              <Form.Item
                layout="vertical"
                label="CONFIRM PASSWORD"
                name="cpassword"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                className="mb_10"
                rules={[
                  {
                    required: true,
                    message: "Please enter confirm password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "The two passwords that you entered do not match!"
                      );
                    },
                  }),
                ]}
              >
                <Input.Password size="large" />
              </Form.Item>
            </Col>
            <Col span="24">
              <Form.Item
                layout="vertical"
                label="CONTACT NUMBER"
                name="contactNumber"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                className="mb_10"
                rules={[
                  {
                    required: true,
                    message: "Please enter your contact number!",
                  },
                  // {
                  //   pattern: /^\d+$/,
                  //   message: 'Please enter valid contact number!',
                  // },
                ]}
              >
                <Input
                  size="large"
                  onKeyDown={(event) => {
                    if (!/[0-9]/.test(event.key) && event.key !== "Backspace") {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span="24">
              <Form.Item
                layout="vertical"
                label="COUNTRY"
                name="country"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                className="mb_10"
                rules={[
                  {
                    required: true,
                    message: "Please select your country!",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="label"
                  size="large"
                  style={{
                    width: "100%",
                  }}
                  options={globalStore.countryList.map((country) => {
                    return {
                      value: country.isoCode,
                      label: country.name,
                    };
                  })}
                  onChange={(e) => globalStore.getRegionByCountry(e)}
                />
              </Form.Item>
            </Col>
            <Col span="24">
              <Form.Item
                layout="vertical"
                label="REGION"
                name="region"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                rules={[
                  {
                    required: true,
                    message: "Please select your region!",
                  },
                ]}
              >
                <Select
                  size="large"
                  showSearch
                  optionFilterProp="label"
                  style={{
                    width: "100%",
                  }}
                  options={globalStore.regionList.map((region) => {
                    return {
                      value: region.name,
                      label: region.name,
                    };
                  })}
                />
              </Form.Item>
            </Col>
            <Col span="24">
              <Form.Item
                layout="vertical"
                label="USER TYPE"
                name="userType"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                className="mb_10"
                rules={[
                  {
                    required: true,
                    message: "Please select your user type!",
                  },
                ]}
              >
                <Select
                  size="large"
                  style={{
                    width: "100%",
                  }}
                  options={[
                    {
                      value: "CLINIC",
                      label: "Clinician",
                    },
                    {
                      value: "PATIENT",
                      label: "Patient",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            {isUpdatingUser ? (
              <Col span="24">
                <Form.Item
                  layout="vertical"
                  label="STATUS"
                  name="status"
                  labelCol={{
                    span: 24,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  className="mb_10"
                  rules={[
                    {
                      required: true,
                      message: "Please select status!",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    style={{
                      width: "100%",
                    }}
                    options={[
                      {
                        value: "1",
                        label: "Active",
                      },
                      {
                        value: "0",
                        label: "Deactive",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
            ) : (
              <></>
            )}
            <Col span="24" className="text_right">
              <Button type="primary" size="large" htmlType="submit">
                {!isUpdatingUser ? "ADD USER" : "UPDATE USER"}
              </Button>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default observer(Users);
