import { makeAutoObservable, runInAction } from "mobx"
import { ADD_SUBSCRIPTION, DELETE_SUBSCRIPTION, GET_ALL_SUBSCRIPTION, UPDATE_SUBSCRIPTION } from "../constants/apiConstants"
import { postRequest } from "../utils/api"

class SubscriptionStore {

    subscriptionData = []
    singleSubscriptionData = {}

    constructor(){
        makeAutoObservable(this)
    }

    addNewSubscription = async (body) => {
        return new Promise( async (resolve, reject) => {
            const { response, error } = await postRequest(ADD_SUBSCRIPTION, body)
            if(!error && response.success){
                return resolve({ success: true, data: response.data })
            }

            return reject({ success: false, data: {} })
        })
    }

    getAllSubscriptions = async (body) => {
        return new Promise( async (resolve, reject) => {
            const { response, error } = await postRequest(GET_ALL_SUBSCRIPTION, body)
            if(!error && response.success){
                this.setSubscriptionData(response.data)
                return resolve({ success: true, data: response.data })
            }

            return reject({ success: false, data: {} })
        })
    }

    deleteSubscriptions = async (body) => {
        return new Promise( async (resolve, reject) => {
            const { response, error } = await postRequest(DELETE_SUBSCRIPTION, body)
            if(!error && response.success){
                // this.setSubscriptionData(response.data)
                return resolve({ success: true, data: response.data })
            }

            return reject({ success: false, data: {} })
        })
    }

    updateSubscriptions = async (body) => {
        return new Promise( async (resolve, reject) => {
            const { response, error } = await postRequest(UPDATE_SUBSCRIPTION, body)
            if(!error && response.success){
                return resolve({ success: true, data: response.data })
            }

            return reject({ success: false, data: {} })
        })
    }




    setSubscriptionData = (data = []) => {
        runInAction(() => {
            this.subscriptionData = data
        })
    }

    setSingleSubscriptionData = (data = {}) => {
        runInAction(() => {
            this.singleSubscriptionData  = data;
        })
    }

}

export default SubscriptionStore