import { makeAutoObservable, runInAction } from "mobx";
import { getRequest, postRequest, postRequest2 } from './../utils/api';
import { ADD_USERS, DELETE_USERS, GET_USERS, UPDATE_USERS } from "../constants/apiConstants";

class UsersStore {


    usersList = []
    singleUserData = {}
    userSearchQuery = ""
    userType = ""

    constructor() {
        makeAutoObservable(this);
    }

    getUsersList = async (body) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response, error } = await postRequest(GET_USERS, body)
    
                if (!error && response.success) {
                    this.setUsersList(Array.isArray(response.data) ? response.data : [])
                    return resolve({ success: true, data: response.data })
                }
                return reject({ success: false, data: {} })
                
            } catch (error) {
                return reject({ success: false, data: {} })
            }
        })
    }

    addNewUser = async (body) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response, error } = await postRequest2(ADD_USERS, body)

                if (!error && response.success) {
                    return resolve({ success: true, data: response.data })
                }
                return reject({ message: response.message})
                
            } catch (error) {
                return reject({ message: error.message})
            }
        })
    }

    updateUser = async (body) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response, error } = await postRequest(UPDATE_USERS, body)
    
                if (!error && response.success) {
                    return resolve({ success: true, data: response.data })
                }
                return reject({ success: false, data: {} })
                
            } catch (error) {
                return reject({ success: false, data: {} })
            }
        })
    }

    deleteUser = async (body) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response, error } = await postRequest(DELETE_USERS, body)
    
                if (!error && response.success) {
                    return resolve({ success: true, data: response.data })
                }
                return reject({ success: false, data: {} })
                
            } catch (error) {
                return reject({ success: false, data: {} })
            }
        })
    }


    setUsersList = (data = []) => {
        runInAction(() => {
            this.usersList = data
        })
    }

    setSingleUserData = (data = {}) => {
        runInAction(() => {
            this.singleUserData = data
        })
    }

    setUserSearchQuery = (data = "") => {
        runInAction(() => {
            this.userSearchQuery = data
        })
    }

    setUserType = (data = "PATIENT") => {
        runInAction(() => {
            this.userType = data
        })
    }

}

export default UsersStore;