import { Spin } from 'antd'
import React from 'react'

const Logout = () => {


    setTimeout(() => {
        window.location.href = "/"
    }, 2000)



  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "90vh" }}>
      <Spin />
    </div>
  )
}

export default Logout
