import React, { useState, useEffect } from "react";
import { Line } from "@ant-design/charts";
// import { LineChartstyle } from "../styles/styles";

export default function LineChart1() {
  const [chartWidth, setChartWidth] = useState(650);

  const data = [
    { day: "Mon", users: 3 },
    { day: "Tue", users: 4 },
    { day: "Wed", users: 10 },
    { day: "Thur", users: 5 },
    { day: "Fri", users: 2 },
    { day: "Sat", users: 6 },
    { day: "Sun", users: 7 },
  ];

  // useEffect(() => {
  //   const handleResize = () => {
  //     console.log("Window resized");
  //     if (window.innerWidth <= 768) {
  //       console.log("Setting chart width for mobile");
  //       setChartWidth(window.innerWidth - 100); // Adjust the width for mobile devices
  //     } else if (window.innerWidth >= 768 && window.innerWidth < 992) {
  //       console.log("Setting chart width for tablet");
  //       setChartWidth(500); // Adjust the width for tablet devices
  //     } else {
  //       console.log("Setting default chart width");
  //       setChartWidth(650); // Default width for non-mobile devices
  //     }
  //   };

  //   // Add event listener for window resize
  //   window.addEventListener("resize", handleResize);

  //   // Call handleResize initially
  //   handleResize();

  //   // Clean up the event listener on component unmount
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  const config = {
    data,
    width: chartWidth,
    autoFit: false,
    xField: "day",
    yField: "users",
    point: {
      size: 5,
      shape: "circle",
    },
    label: {
      style: {
        fill: "#aaa",
      },
    },
  };

  return (
    <div>
      <Line {...config} />
      {console.log("Chart rendered with width:", chartWidth)}
    </div>
  );
}
