import { makeAutoObservable, runInAction } from "mobx";
import { ADD_DISORDER, ADD_MATERIAL, DELETE_DISORDER, DELETE_MATERIAL, GET_ALL_DISORDER, GET_ALL_MATERIAL, UPDATE_DISORDER, UPDATE_MATERIAL } from "../constants/apiConstants";
import { postRequest } from "../utils/api";

class DisorderStore {

    disorderList = []
    disorderCounts = {totalDisorders: 0, totalMaterials: 0}
    singleDisorderData = [{}]
    disorderSearchQuery = ""
    isAddingMaterial = false
    videoMaterial = []
    documentMaterial = []
    singleVideoMaterial = {}
    singleDocumentMaterial = {}
    isUpdatingVideoMaterial = false


    // Modal State
    updateDisorderModalStatus = false
    addVideoMaterialModalStatus = false
    videoMaterialModalStatus = false
    viewSingleVideoMaterialModalStatus = false
    editDocumentMaterialModalStatus = false

    documentMaterialDrawerStatus = false
    addDocumentMaterialDrawerStatus = false
    viewDocumentDrawerStatus = false

    constructor() {
        makeAutoObservable(this);
    }

    getAllDisorderList = async (body) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response, error } = await postRequest(GET_ALL_DISORDER, body)

                console.log('response', response)

            if (!error && response.success) {
                this.setDisorderList(Array.isArray(response.data.disorders) ? response.data.disorders : [] )
                this.setDisorderCounts(Array.isArray(response.data.disorders) ? {totalDisorders: response.data.totalDisorders, totalMaterials: response.data.totalMaterials} : {totalDisorders: 0, totalMaterials: 0} )
                return resolve({ success: true, data: response.data })
            }
            return reject({ success: false, data: {} })
            } catch (error) {
                return reject({ success: false, data: {} })
            }

        })
    }

    deleteADisorder = async (body) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response, error } = await postRequest(DELETE_DISORDER, body)
                if(!error && response.success){
                    return resolve({ success: true, data: response.data })
                }
                return reject({ success: false, data: [] })
            } catch (error) {
                return reject({ success: false, data: [] })
            }
        })
    }

    updateDisorder = async (body) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response, error } = await postRequest(UPDATE_DISORDER, body)
                if(!error && response.success){
                    return resolve({ success: true, data: response.data })
                }
                return reject({ success: false, data: [] })
            } catch (error) {
                return reject({ success: false, data: [] })
            }
        })
    }

    addNewDisorder = async (body) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response, error } = await postRequest(ADD_DISORDER, body)
                if(!error && response.success){
                    return resolve({ success: true, data: response.data })
                }
                return reject({ success: false, data: [] })
            } catch (error) {
                return reject({ success: false, data: [] })
            }
        })
    }

    addNewVideoMaterial = async (body) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response, error } = await postRequest(ADD_MATERIAL, body)
                if(!error && response.success){
                    console.log(response.data, "response.data")
                    return resolve({ success: true, data: response.data, error: {} })
                }
                return reject({ success: false, data: [], error: response })
            } catch (error) {
                return reject({ success: false, data: [], error })
            }
        })
    }

    getMaterial = async (body) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response, error } = await postRequest(GET_ALL_MATERIAL, body)
                if(!error && response.success){
                    if(body.materialType === "VIDEO"){
                        this.setVideoMaterial(Array.isArray(response.data) ? response.data : [])
                    }

                    if(body.materialType === "DOCUMENT"){
                        this.setDocumentMaterial(Array.isArray(response.data) ? response.data : [])}


                    return resolve({ success: true, data: response.data, error: {} })
                }
                return reject({ success: false, data: [], error: response })
            } catch (error) {
                return reject({ success: false, data: [], error })
            }
        })
    }


    deleteAMaterial = async (id) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response, error } = await postRequest(DELETE_MATERIAL, {materialId: id})
                if(!error && response.success){
                    return resolve({ success: true, data: response.data })
                }
                return reject({ success: false, data: [] })
            } catch (error) {
                return reject({ success: false, data: [] })
            }
        })
    }

    updateAMaterial = async (body) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response, error } = await postRequest(UPDATE_MATERIAL, body)
                if(!error && response.success){
                    return resolve({ success: true, data: response.data })
                }
                return reject({ success: false, data: [] })

            }
            catch (error) {
                return reject({ success: false, data: [] })
            }
        })
    }

    setDisorderList = (data = []) => {
        runInAction(() => {
            this.disorderList = data
        })
    }

    setDisorderSearchQuery = (data = "") => {
        runInAction(() => {
            this.disorderSearchQuery = data
        })
    }

    setSingleDisorderData = (data = []) => {
        runInAction(() => {
            this.singleDisorderData = data
        })
    }

    setUpdateDisorderModalStatus = (data = false) => {
        runInAction(() => {
            this.updateDisorderModalStatus = data
        })
    }

    setAddVideoMaterialModalStatus = (data = false) => {
        runInAction(() => {
            this.addVideoMaterialModalStatus = data
        })
    }

    setIsAddingMaterial = (value = false) => {
        runInAction(() => {
            this.isAddingMaterial = value
        })
    }

    setVideoMaterialModalStatus = (value= false) => {
        runInAction(() => {
            this.videoMaterialModalStatus = value
        })
    }

    setVideoMaterial = (data = []) => {
        runInAction(() => {
            this.videoMaterial = data
        })
    }

    setDocumentMaterial = (data = []) => {
        runInAction(() => {
            this.documentMaterial = data
        })
    }

    setSingleVideoMaterial = (data = []) => {
        runInAction(() => {
            this.singleVideoMaterial = data
        })
    }

    setViewSingleVideoMaterialModalStatus = (value = false) => {
        runInAction(() => {
            this.viewSingleVideoMaterialModalStatus = value
        })
    }

    setDocumentMaterialDrawerStatus = (value = false) => {
        runInAction(() => {
            this.documentMaterialDrawerStatus = value
        })
    }

    setAddDocumentMaterialDrawerStatus = (value = false) => {
        runInAction(() => {
            this.addDocumentMaterialDrawerStatus = value
        })
    }

    setSingleDocumentMaterial = (data = {}) => {
        runInAction(() => {
            this.singleDocumentMaterial = data
        })
    }

    setViewDocumentDrawerStatus = (value = false) => {
        console.log('value', value)
        runInAction(() => {
            this.viewDocumentDrawerStatus = value
        })
    }

    setDisorderCounts = (data = {totalDisorders: 0, totalMaterials: 0}) => {
        runInAction(() => {
            this.disorderCounts = data
        })
    }

    setEditDocumentMaterialModalStatus = (value = false) => {
        runInAction(() => {
            this.editDocumentMaterialModalStatus = value
        })
    }

    setIsUpdatingVideoMaterial = (value = false) => {
        runInAction(() => {
            this.isUpdatingVideoMaterial = value
        })
    }

}

export default DisorderStore