import React, { useState } from "react";
import { Statistic, Col, Card, Row, Avatar, List, Flex } from "antd";
import { UserSwitchOutlined } from "@ant-design/icons";
import PieChart from "../components/PieChart";
import CountryChart from "../components/CountryChart";
import {
  Chart,
  LineC,
  PieC,
  Listtype,
  Disorders,
  Materials,
} from "../styles/styles";
import LineChart1 from "../components/LineChart1";

const disorderData = [
  {
    title: "Disorder 1",
  },
  {
    title: "Disorder 2",
  },
  {
    title: "Disorder 3",
  },
  {
    title: "Disorder 4",
  },
];

const materialData = [
  {
    title: "Material 1",
  },
  {
    title: "Material 2",
  },
  {
    title: "Material 3",
  },
  {
    title: "Material 4",
  },
];

export default function Dashboard() {
  const [loading, setLoading] = useState(false);
  const [customerCount, setCustomerCount] = useState([]);
  const [providersCount, SetProvidersCount] = useState([]);

  const data = [
    { title: "Subscribed User", value: 120 },
    { title: "Guest Users", value: 120 },
    { title: "Clinicians", value: 120 },
    { title: "Total Materials", value: 120 },
    { title: "Total Patients", value: 2120 },
    { title: "Total Clinicians", value: 1120 },
  ];

  return (
    <>
      {/* <div
        style={{
          padding: 20,
        }}
      >
        <Row gutter={18}>
          
        </Row>
      </div> */}

      <div style={{ padding: 20 }}>
        <Row gutter={[16, 20]}>
          {data.map((item, index) => (
            <Col key={index} span={4} lg={4} md={8} sm={4} xs={12}>
              <Card bordered={true}>
                <Statistic
                  style={{ fontWeight: 600 }}
                  title={item.title}
                  value={item.value}
                  prefix={
                    <div
                      style={{
                        paddingLeft: 10,
                        paddingRight: 10,
                        marginRight: 10,
                        backgroundColor: "#35A8DF",
                        borderRadius: 4,
                      }}
                    >
                      <UserSwitchOutlined
                        style={{ color: "white", width: 14 }}
                      />
                    </div>
                  }
                />
              </Card>
            </Col>
          ))}
        </Row>
      </div>

      <Chart>
        <LineC>
          <h1 style={{ fontSize: 24, fontWeight: 400 }}>Daily Users</h1>
          <LineChart1 />
        </LineC>
        <PieC>
          <PieChart />
        </PieC>
      </Chart>

      <Listtype>
        <Disorders>
          <h1 style={{ fontSize: 24, fontWeight: 400 }}>Most Used Disorders</h1>
          <List
            itemLayout="horizontal"
            dataSource={disorderData}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=${index}`}
                    />
                  }
                  title={<a href="/materials">{item.title}</a>}
                />
              </List.Item>
            )}
          />
        </Disorders>
        <Materials>
          <h1 style={{ fontSize: 24, fontWeight: 400 }}>Most Used Materials</h1>
          <List
            itemLayout="horizontal"
            dataSource={materialData}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=${index}`}
                    />
                  }
                  title={<a href="/materials">{item.title}</a>}
                />
              </List.Item>
            )}
          />
        </Materials>
      </Listtype>
      <div style={{ marginLeft: 30, marginRight: 30, paddingBottom: 90 }}>
        <CountryChart />
      </div>
    </>
  );
}
