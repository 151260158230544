import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMainStore } from "../store/mainStore";

export default function Protected(props) {
  const navigate = useNavigate();

  const mainStore = useMainStore()
  const { loginStore } = mainStore
  const { Component } = props;

  useEffect(() => {
    if (!loginStore.loginUserToken) {
      navigate("/", { replace: true });
    }
  }, []);

  return <Component />;
}
