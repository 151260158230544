import { makeAutoObservable, runInAction } from "mobx"
import { postRequest } from "../utils/api"
import { USER_LOGIN } from './../constants/apiConstants';
import { makePersistable } from "mobx-persist-store";

class LoginStore {

    loginUserData = {}
    loginUserToken = {}

    constructor(stores){
        this.stores = stores
        makeAutoObservable(this)
        makePersistable(this, { name: "token", properties: ["loginUserToken"], storage: window.localStorage })
    }

    userLogin = async (body) => {
        return new Promise( async (resolve, reject) => {
            try {
                const { response, error } = await postRequest(USER_LOGIN, body)
    
                if(!error && response.success){
                    const { data } = response
    
                    this.setLoginUserToken(data.sessionInfo)
                    this.setLoginUserData(data)
                    return resolve({ success: true, data: data })
                }

                return reject({ message: response.message })
            } catch (error) {
                return reject(error)
            }
        })
    }

    setLoginUserData = (data = {}) => {
        runInAction(() => {
            this.loginUserData  = data
        })
    }

    setLoginUserToken = (token = "") => {
        runInAction(() => {
            this.loginUserToken  = token
        })
    }

}

export default LoginStore