import { Button, Drawer, Image, Modal, Row } from "antd";
import { Observer } from "mobx-react";
import React, { Fragment, useEffect, useState } from "react";
import { useMainStore } from "../../store/mainStore";
import { toJS } from "mobx";

const VIewVideoMaterial = () => {
  const mainStore = useMainStore();
  const { disorderStore } = mainStore;

  const [viewVideo, setViewVideo] = useState(false);

  useEffect(() => {
    // Effect to run when the drawer is opened to ensure re-render
    if (disorderStore.viewSingleVideoMaterialModalStatus) {
      disorderStore.setViewSingleVideoMaterialModalStatus(false);
      disorderStore.setViewSingleVideoMaterialModalStatus(true);
    }
  }, [disorderStore.viewSingleVideoMaterialModalStatus]);

  return (
    <Observer>
      {() => {
        const {
          viewSingleVideoMaterialModalStatus,
          setViewSingleVideoMaterialModalStatus,
          singleVideoMaterial,
        } = disorderStore;

        if (!singleVideoMaterial || !singleVideoMaterial.uploadedFile) {
          return <div>Loading...</div>;
        }
        return (
          <Fragment>
            <Drawer
              title="Video Material Preview"
              width={500}
              onClose={() => setViewSingleVideoMaterialModalStatus()}
              open={viewSingleVideoMaterialModalStatus}
              maskClosable={false}
            >
              <Row>
                <div className="subscription_details">
                  <div className="label">Videos</div>
                  <video
                    width="100%"
                    controls
                    autoPlay={true}
                    key={singleVideoMaterial.uploadedFile}
                  >
                    <source
                      src={`https://api.scalegreat.in/${singleVideoMaterial?.uploadedFile}`}
                      // src={singleVideoMaterial.uploadedFileUrl}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div className="subscription_details">
                  <div className="label">VIDEO NAME</div>
                  <div className="value">{singleVideoMaterial?.name}</div>
                </div>
                <div className="subscription_details">
                  <div className="label">DESCRIPTION</div>
                  <div className="value">
                    {singleVideoMaterial?.description}
                  </div>
                </div>
                <div className="subscription_details">
                  <div className="label">MATERIAL ACCESS</div>
                  <div className="value">
                    {singleVideoMaterial?.access?.join(", ")}
                  </div>
                </div>
                <div className="subscription_details">
                  <div className="label">CAN WE ADD AS A NEW MATERIAL?</div>
                  <div className="value">
                    {singleVideoMaterial?.isNewMaterial ? "Yes" : "No"}
                  </div>
                </div>
                <div className="subscription_details">
                  <div className="label">ENTER NEW MATERIAL VALUE</div>
                  <div className="value">
                    {singleVideoMaterial?.materialValue}
                  </div>
                </div>
              </Row>
            </Drawer>

            <Modal
              open={viewVideo}
              onCancel={() => setViewVideo(false)}
              footer={null}
              maskClosable={false}
            >
              <video
                width="100%"
                height={"100%"}
                controls
                autoPlay={true}
                style={{ marginTop: "30px" }}
              >
                <source
                  src={`https://api.scalegreat.in/${singleVideoMaterial?.uploadedFile}`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </Modal>
          </Fragment>
        );
      }}
    </Observer>
  );
};

export default VIewVideoMaterial;
